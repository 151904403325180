import React, { useContext } from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { getPrices } from '../../data/prices'

import Layout from '../../components/Layout'
import Section from '../../components/Section'
import Hero from '../../components/Hero'
import TrustedBySection from '../../components/TrustedBySection'
import BookDemoSection from '../../components/BookDemoSection'
import CustomerStorySliderSection from '../../components/CustomerStorySliderSection'
import PricingPlanCard from '../../components/PricingPlanCard'

import customerStories from '../../data/customerStories'

import bannerIcon1 from '../../images/features2023/performance-reviews/Top_Banner_Icons/PNG/MyHR-Platform-All-in-one HR@2x.png'
import bannerIcon2 from '../../images/features2023/performance-reviews/Top_Banner_Icons/PNG/MyHR-Platform-Customised Documents@2x.png'
import bannerIcon3 from '../../images/features2023/performance-reviews/Top_Banner_Icons/PNG/MyHR-Platform-Powered by HR Professionals@2x.png'

import home2022_1 from '../../images/home2022/MyHR-Home_Hiring-and-Onboarding@2x.png'
import home2022_2 from '../../images/home2022/MyHR-Home_Compliance-and-advisory@2x.png'
import home2022_3 from '../../images/home2022/MyHR-Home_Admin-and-Reporting@2x.png'
import home2022_4 from '../../images/home2022/MyHR-Home_Performance-and-Development@2x.png'
import home2022_5 from '../../images/home2022/MyHR-Home_Leave-and-payroll@2x.png'

import seaToSkyPhysioLogo from '../../images/home2022/SeaToSkyPhysio.jpg'
import silverBackLogo from '../../images/home2022/SilverBack.jpg'
import ventureWebLogo from '../../images/home2022/VentureWeb.png'
import LocaleContext from '../../components/LocaleContext'

class HubspotForm extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '3837251',
          formId: '1be7da00-c34a-4cea-bdf7-2d4c494421e4',
          target: '#hubspotForm',
        })
      }
    })
  }

  render() {
    return <div id="hubspotForm"></div>
  }
}

const PAGE_CONFIG = {
  locale: 'en-CA',
  meta: {
    title: 'HR Software & Support for Small and Medium businesses - Request a Demo',
    description:
      'We are HR support experts offering consultancy services and easy-to-use HR software in Canada. Learn how our trusted platform can streamline HR for your businesses.',
  },
  tourLink: 'https://app.storylane.io/share/khkknhixy6ug',
  trialLink: '/nz/trial',
  demoLink: '#scrollToForm',
}

export default () => {

  /**
   * New subscription cost calculation
   * See README-subscription-price-calculation.md
   */
  const calculatePrice = people => {
    const locale = useContext(LocaleContext)
    const prices = getPrices(locale.value)
    
    let price = prices
      .sort((a, b) =>
        a.tier_up_to < b.tier_up_to ? -1 : a.tier_up_to == b.tier_up_to ? 0 : 1
      )
      .find(({ tier_up_to }) => people <= tier_up_to)
    if (!price) {
      return '??'
    }
    return price.tier_price_mode === 'flat' ? price.price : people * price.price
  }

  const calculatedCostText = people => {
    const locale = useContext(LocaleContext)
    if (people === 1) return <b>Free for 15 days</b>
    else if (people >= 251) return <b>CALL US</b>

    return (
      <>
        <b>${calculatePrice(people)}</b>
        <small>
          /month +GST <b>{locale.currency}</b>
        </small>
      </>
    )
  }

  return (
    <Layout
      className="HomePage"
      title={PAGE_CONFIG.meta.title}
      description={PAGE_CONFIG.meta.description}
      locale={PAGE_CONFIG.locale}
    >
      <Hero variant="home">
        <h1>Smart HR software. Expert HR support.</h1>
        <p>
          MyHR integrates smart HR software and HR advisory support to simplify
          people management for everyone.
        </p>
        <Button color="cyan" href={PAGE_CONFIG.demoLink} className="mr-3">
          Book a demo
        </Button>
        <Button
          className="storylane"
          color="outline-cyan"
          href={PAGE_CONFIG.tourLink}
          target="_blank"
        >
          <FontAwesomeIcon icon={faPlay} className="mr-2 ml-0" />
          Take the tour
        </Button>
      </Hero>

      <TrustedBySection
        labelCol={{ lg: 4 }}
        labelClassName="text-lg-right pr-lg-5"
        itemCol={{ xs: 4, lg: 3 }}
        items={[
          <img src={ventureWebLogo} alt="Venture Web logo" />,
          <img src={seaToSkyPhysioLogo} alt="Sea to Sky Sport Physio logo" />,
          <img src={silverBackLogo} alt="SilverBack logo" />,
        ]}
      />

      <Section className="Content bg-gradient-gray-white-b">
        <Container>
          <h2 className="text-center my-5">
            Manage people, payroll and compliance, in one platform
          </h2>

          <div className="sectionLink">
            <Row className="mt-5">
              <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6">
                <img
                  src={home2022_1}
                  className=""
                  alt="HR outsourcing company MyHR"
                />
              </Col>
              <Col
                md={12}
                lg={6}
                className="py-6 px-4 px-md-6 d-flex flex-column justify-content-center"
              >
                <h4>Hiring & Onboarding</h4>
                <h3>Hire new employees fast and set them up for success</h3>
                <p>
                  Hire new employees quickly with e-signed contracts then set
                  them up for success with paperless onboarding. Create
                  automated, customised onboarding plans that trigger handbook
                  and policy acceptance, save required certifications and
                  support the creation of a strong company culture.
                </p>
              </Col>
            </Row>
          </div>

          <div className="sectionLink">
            <Row className="mt-5">
              <Col
                md={12}
                lg={6}
                className="py-6 px-4 px-md-6 d-flex flex-column justify-content-center order-2 order-md-1"
              >
                <h4>HR Compliance and Advisory Support</h4>
                <h3>Expert support for any employee issue</h3>
                <p>
                  Cloud-based contracts, policies, and HR documents on demand,
                  built for your business and 100% paperless. Get on-call HR
                  advice to support you with the management of your employees.
                  Make changes to your business with an HR expert backing you
                  every step of the way.
                </p>
              </Col>
              <Col
                md={12}
                lg={6}
                className="pb-0 pb-md-6 px-5 px-md-6 pt-6 order-1 order-md-2"
              >
                <img
                  src={home2022_2}
                  className=""
                  alt="Best hr advice online MyHR"
                />
              </Col>
            </Row>
          </div>

          <div className="sectionLink">
            <Row className="mt-5">
              <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6">
                <img
                  src={home2022_3}
                  className=""
                  alt="Best hr platform for small business MyHR"
                />
              </Col>
              <Col
                md={12}
                lg={6}
                className="py-6 px-4 px-md-6 d-flex flex-column justify-content-center"
              >
                <h4>Admin & Reporting</h4>
                <h3>Benchmark, manage and track your people</h3>
                <p>
                  Create multi-level access with 2-factor authentication and
                  employee self-service. Access org reports and insights, and
                  benchmark your people metrics against industry peers. Create
                  custom reminders and track licences, visas, training, events
                  and attendance.
                </p>
              </Col>
            </Row>
          </div>

          <div className="sectionLink">
            <Row className="mt-5">
              <Col
                md={12}
                lg={6}
                className="py-6 px-4 px-md-6 d-flex flex-column justify-content-center order-2 order-md-1"
              >
                <h4>Performance & Development</h4>
                <h3>Boost employee performance and productivity</h3>
                <p>
                  Maximise performance. Minimise admin. Build an engaged and
                  productive team and cover all aspects of employee development
                  with user-friendly tools for Objectives and Key Result
                  tracking and goal setting, smart note capture, automated
                  templates, Performance Improvement Plans, Integrated Learning
                  Management Systems, and more. All injected with expert help
                  from our HR advisory team. All injected with expert help from
                  our HR advisory team.
                </p>
              </Col>
              <Col
                md={12}
                lg={6}
                className="pb-0 pb-md-6 px-5 px-md-6 pt-6 order-1 order-md-2"
              >
                <img
                  src={home2022_4}
                  className=""
                  alt="HR Performance & Development MyHR"
                />
              </Col>
            </Row>
          </div>

          <div className="sectionLink">
            <Row className="mt-5">
              <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6">
                <img
                  src={home2022_5}
                  className=""
                  alt="Best hr software small business MyHR"
                />
              </Col>
              <Col
                md={12}
                lg={6}
                className="py-6 px-4 px-md-6 d-flex flex-column justify-content-center"
              >
                <h4>Employee Leave Management</h4>
                <h3>Streamline leave</h3>
                <p>
                  Track, manage, and approve employee leave requests. Review in
                  a shared calendar, and streamline the process with our
                  employee self-service feature.
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </Section>

      <Section
        className="BookDemoFormSection pb-6 bg-gradient-blue-cyan text-white"
        id="scrollToForm"
      >
        <Container>
          <Row>
            <Col md={7} className="px-4 py-4">
              <h2 className="mb-4">Book your personalized demo</h2>

              <div className="BookDemoFormSection__mainIcon">
                <div>
                  <img src={bannerIcon1} />
                </div>
                <div>
                  <h4>All-in-one HR software</h4>
                  <p>
                    Streamline onboarding, payroll, leave and employee
                    performance, in one place.
                  </p>
                </div>
              </div>
              <div className="BookDemoFormSection__mainIcon">
                <div>
                  <img src={bannerIcon2} />
                </div>
                <div>
                  <h4>Customised documents</h4>
                  <p>
                    Take care of compliance with cloud-based contracts, policies
                    and on-demand employment documents, tailor-made for your
                    business.
                  </p>
                </div>
              </div>
              <div className="BookDemoFormSection__mainIcon">
                <div>
                  <img src={bannerIcon3} />
                </div>
                <div>
                  <h4>Powered by HR professionals</h4>
                  <p>
                    Get the best outcomes for your team and business with access
                    to on-call HR experts.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={5} className="px-4 mt-5">
              <HubspotForm />
            </Col>
          </Row>
        </Container>
      </Section>

      <CustomerStorySliderSection
        stories={[
          {
            ...customerStories['silverback'],
            moreStoriesButton: true,
          },
        ]}
        stars={true}
        theme="light"
      />

      <Section className="bg-gradient-navy-blue">
        <Container>
          <h2 className="text-white text-center my-5">MyHR plans and pricing</h2>
          <Row>
            <Col md={6} lg={4} className="py-5">
              <PricingPlanCard
                title="Small Business"
                subtitle="UP TO 20 EMPLOYEES"
                summary="For companies with no internal HR*"
                price={{
                  prefix: 'From',
                  value: '$295',
                  suffix: '/month',
                }}
                calculateCost={{
                  minEmployees: 1,
                  maxEmployees: 251,
                  defaultEmployees: 10,
                  costText: calculatedCostText,
                }}
                description={
                  <>
                    <p className="font-weight-bold">Includes:</p>
                    <ul>
                      <li>HR Advisory (On-Call)</li>
                      <li>HR Documents</li>
                      <li>Paperless Onboarding</li>
                      <li>Performance Reviews</li>
                      <li>Leave Management</li>
                      <li>Payroll Integration</li>
                      <li>Training & Licence Tracking</li>
                      <li>Digital Doc Acceptance</li>
                    </ul>
                  </>
                }
                notes={
                  <>
                    <p>
                      *Make people management easy. Fit-for-purpose HR
                      documents, contracts, and compliance. Support for any
                      tricky HR issues, and an easy-to-use HRIS to manage
                      payroll, leave, and performance. It’s the HR department
                      for businesses that don’t have one.
                    </p>
                    <p className="font-weight-bold">No lock-in contract</p>
                  </>
                }
              />
            </Col>
            <Col md={6} lg={4} className="py-5">
              <PricingPlanCard
                title="Medium Business"
                subtitle="21-100 EMPLOYEES"
                summary="For companies with or without internal HR*"
                price={{
                  prefix: 'From',
                  value: '$420',
                  suffix: '/month',
                }}
                calculateCost={{
                  minEmployees: 16,
                  maxEmployees: 251,
                  defaultEmployees: 21,
                  costText: calculatedCostText,
                }}
                description={
                  <>
                    <p className="font-weight-bold">
                      Includes everything in Small, plus:
                    </p>
                    <ul>
                      <li>HR Business Partner</li>
                      <li>Customised HR Documents</li>
                      <li>Recruitment Strategy</li>
                      <li>ATS Integration (Job Adder)</li>
                      <li>LMS Integration (ELMO)</li>
                      <li>Custom Surveys (optional)</li>
                      <li>Custom Data Reporting (optional)</li>
                      <li>Full-Service Recruitment (optional)</li>
                    </ul>
                  </>
                }
                notes={
                  <>
                    <p>
                      *Scale your business, not your admin team. Perfect for
                      growing businesses needing a customised HR product and
                      partner for additional support, issues management, and
                      documentation overload. Streamline recruitment,
                      onboarding, and performance, and access enterprise
                      products and services if required.
                    </p>
                    <p className="font-weight-bold">No lock-in contract</p>
                  </>
                }
              />
            </Col>
            <Col md={6} lg={4} className="py-5">
              <PricingPlanCard
                title="Enterprise"
                subtitle="100+ EMPLOYEES"
                summary="Customised HR solutions*"
                price={{
                  value: 'Call us',
                }}
                calculateCost={{
                  minEmployees: 101,
                  maxEmployees: 251,
                  defaultEmployees: 101,
                  costText: calculatedCostText,
                }}
                description={
                  <>
                    <p className="font-weight-bold">
                      Includes everything in Small &amp; Medium, plus:
                    </p>
                    <ul>
                      <li>Dedicated Account Manager</li>
                      <li>Custom Data Reporting</li>
                      <li>Custom Branding</li>
                      <li>Editable HR Documents</li>
                      <li>Remuneration Benchmarking</li>
                      <li>Custom Forms</li>
                      <li>Custom Integrations</li>
                      <li>Leadership &amp; Culture</li>
                    </ul>
                  </>
                }
                notes={
                  <>
                    <p>
                      *Solutions tailored for large multi-dimensional
                      organisations. Whether you need dedicated recruitment
                      support or a custom API with your bespoke POS system or
                      payroll, we’ve got you covered. Contact one of our
                      enterprise solution specialists to build a plan that fits
                      your business and HR needs.
                    </p>
                    <p className="font-weight-bold">Minimum 1-year contract</p>
                  </>
                }
              />
            </Col>
          </Row>
        </Container>
      </Section>

      <BookDemoSection
        bookDemoLink={PAGE_CONFIG.demoLink}
        trialLink={PAGE_CONFIG.trialLink}
      />
    </Layout>
  )
}
