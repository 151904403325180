import React, { useState } from 'react';
import { Modal } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Slider from 'rc-slider'

import './PricingPlanCard.scss';

export default (props) => {
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [ employees, setEmployees ] = useState((props.calculateCost && props.calculateCost.defaultEmployees) || 1);

  const toggleModal = e => setIsModalOpen(!isModalOpen)

  return (
    <>
      <div className="PricingPlanCard d-flex flex-column">
        <div className="PricingPlanCard__title">{props.title}</div>
        <div className="PricingPlanCard__subtitle mb-3">{props.subtitle}</div>
        <div className="PricingPlanCard__summary mb-3">{props.summary}</div>

        <div>
          {props.price &&
            <div className="PricingPlanCard__price mb-3">
              <div className="PricingPlanCard__pricePrefix">{props.price.prefix}</div>
              <span className="PricingPlanCard__priceValue">{props.price.value}</span>
              <span>{props.price.suffix}</span>
            </div>
          }

          {props.calculateCost &&
            <span
              className="PricingPlanCard__calculateButton"
              onClick={toggleModal}
            >
              Calculate costs <FontAwesomeIcon icon={faArrowRight} />
            </span>
          }
        </div>
        <div>
          <hr/>
        </div>
        <div className="PricingPlanCard__description">{props.description}</div>
        <div className="PricingPlanCard__notes">{props.notes}</div>
      </div>

      {props.calculateCost &&
        <Modal isOpen={isModalOpen} toggle={toggleModal}>
          <div className="PricingPlanCard__calculateCost">
            <h3>
              Calculate costs <FontAwesomeIcon icon={faArrowRight} />
            </h3>
            <p>
              Pay a monthly fee based on the number of employees you have, plus
              a one-time account setup, configuration & onboarding fee.
              All-inclusive, cost-effective, unlimited support.
            </p>
            <div className="PricingPlanCard__calculateCostDetails">
              <div>
                {employees === 1 ? '1 person' : `${employees} people`}
              </div>
              {props.calculateCost.costText &&
                <div className="PricingPlanCard__calculateCostValue">
                  {props.calculateCost.costText(employees)}
                </div>
              }
            </div>
            <Slider
              min={props.calculateCost.minEmployees}
              max={props.calculateCost.maxEmployees}
              defaultValue={employees}
              onChange={val => setEmployees(val)}
            />
          </div>
        </Modal>
      }
    </>

  )
}